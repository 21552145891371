<template>
  <div>
    <a-card title="线下销售单收款">
      <a-button slot="extra" type="primary" ghost @click="() => { this.$router.go(-1); }"> <a-icon type="left" />返回</a-button>
      <a-table
            rowKey="id"
            size="middle"
            :columns="columnsOrder"
            :data-source="sales_order_items"
            :pagination="false"
            />

      <a-divider orientation="left" style="margin-top: 30px;">总欠款金额：{{ arrears_amount }}，批量收款不允许收取部分款项，只能整体收取，只能收取到一个账户。</a-divider>
      <div>
          <div style="margin-top: 16px;">
              <a-table rowKey="id" size="middle" :columns="columnsAccount" :data-source="sales_account_items"
                :pagination="false">
                <div slot="account" slot-scope="value, item, index">
                  <a-select :disabled = "item.collection_amount > 0" v-if="!item.isTotal" v-model="item.account" style="width: 100%" @change="(value) => changeAccount(value, item, index)">
                    <a-select-option v-for="Account in accountsItems" :key="Account.id"
                      :value="Account.id">
                      {{ Account.name }}
                    </a-select-option>
                  </a-select>
                </div>

                <div slot="receipt_amount" slot-scope="value, item, index">
                  <div v-if="item.isTotal">{{ value }}</div>
                  <a-input-number v-else style="width: 100%"
                    v-model="receipt_amount"
                    :disabled = "true"
                    :min="0"
                    :precision="2"></a-input-number>
                </div>

              </a-table>
          </div>
        </div>


      <div style="margin-top: 32px;">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading">创建</a-button>
        </a-popconfirm>
      </div>

    </a-card>
  </div>
</template>

<script>
  import { saleOrderDetail, saleOrderReceipt } from '@/api/sale'
  import { clientArrearsOption, userOption, accountsOption } from '@/api/option'
  import JsBarcode from 'jsbarcode'
  import NP from 'number-precision'

export default {
    data() {
      return {
        warehouseItems: [],
        handlerItems: [],
        accountsItems: [],
        materialsSelectModalVisible: false,
        model: {},
        form: {},
        loading: false,
        materialLoading: false,
        info: {},
        columnsOrder: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '销售编号',
            dataIndex: 'number',
            sorter: true,
            width: 120,
          },
          {
            title: '客户',
            dataIndex: 'client_name',
            width: 100
          },
          {
            title: '销售产品',
            customRender: (value, item, index) => {
              let products = ""
              for (let i in item.sales_goods_items){
                  products = products + item.sales_goods_items[i].goods_name + ','
              }
              return products.substring(0,20)
            },
            width: 160,
          },
          {
            title: '处理日期',
            dataIndex: 'handle_time',
            width: 120
          },
          {
            title: '销售总数量',
            dataIndex: 'total_quantity',
            width: 80
          },
          {
            title: '销售总金额',
            dataIndex: 'total_amount',
            width: 100
          },
          {
            title: '欠款金额',
            dataIndex: 'arrears_amount',
            width: 100
          },
        ],
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '产品名称',
            dataIndex: 'goods_name',
            key: 'goods_name',
            width: 150,
          },
          {
            title: '产品编号',
            dataIndex: 'goods_number',
            key: 'goods_number',
            width: 150,
          },
          {
            title: '单位',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: 80,
          },
          {
            title: '销售数量',
            dataIndex: 'sales_quantity',
            key: 'sales_quantity',
            width: 120,
          },
          {
            title: '销售单价(元)',
            dataIndex: 'sales_price',
            key: 'sales_price',
            width: 120,
          },
          {
            title: '金额',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: 200,
            customRender: (value, item) => {
              if (item.isTotal) return value;
              value = NP.times(item.sales_quantity, item.sales_price);
              return item.id ? NP.round(value, 2) : ''
            },
          }
        ],
        columnsAccount: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '收款账户',
            dataIndex: 'account',
            key: 'account',
            width: 200,
            scopedSlots: { customRender: 'account' },
          },

          {
            title: '收款金额',
            dataIndex: 'receipt_amount',
            key: 'receipt_amount',
            width: 200,
            scopedSlots: { customRender: 'receipt_amount' },
          },
        ],
        sales_account_items:[],
        sales_order_items:[],
        collection_amount:0,
        arrears_amount:0,
        total_amount:0,
        receipt_amount:0,

      }
    },

    computed: {
    },

    methods: {

      handleSelectChange () {
      },
      onRowClick(event){
        console.log('row click event:', event)
        for (let i in this.sales_order_items[0].sales_account_items){
              this.$set(data.sales_account_items[i], 'receipt_amount', 0)
              this.sales_account_items.push(data.sales_account_items[i])
          }
      },
      initData() {
        this.loading = true;
        this.sales_order_items = [];
        //console.log('route.query.ids', this.$route.query.ids)
        this.collection_amount = 0
        for (let o in this.$route.query.ids) {
          saleOrderDetail({ id: this.$route.query.ids[o] }).then(data => {
          //console.log('this.collection_amount', this.collection_amount, data.collection_amount)
          //console.log('this.arrears_amount', this.arrears_amount, data.arrears_amount)
          this.collection_amount = this.collection_amount +  parseFloat(data.collection_amount)
          this.arrears_amount = this.arrears_amount + parseFloat(data.arrears_amount)
          this.total_amount = this.total_amount + parseFloat(this.total_amount)
          this.receipt_amount = this.arrears_amount
          this.sales_order_items.push(data)
        }).finally(() => {
          this.loading = false;
        });
        }
        //初始化付款账户和付款金额，只允许添加一个账户
        this.sales_account_items.push({
          id: this.sales_account_items.length + 1,
          account: '',
          collection_amount: this.collection_amount,
          receipt_amount: this.arrears_amount,
        });


        accountsOption({ page_size: 999999, is_active: true }).then(data => {
          this.accountsItems = data.results;
        });
      },

      create() {
        let msg = ''
        if (this.sales_account_items[0].account == ''){
          this.$message.warn('创建前必须选择收款账户！');
          return;
        }
        for (let o in this.sales_order_items){
          let sai = this.sales_order_items[o].sales_account_items;
          let existflag = true;
          for (let s in sai){
            if (this.sales_account_items[0].account == sai[s].account) {
              existflag = false;
              sai[s].collection_amount = this.sales_order_items[o].collection_amount;
              sai[s]['receipt_amount'] = this.sales_order_items[o].arrears_amount;
            } else {
              sai[s]['receipt_amount'] = 0;
            }
          }//for let s in sai
          if (existflag) {
            let acc = {
              account: this.sales_account_items[0].account,
              collection_amount: this.sales_order_items[o].collection_amount,
              receipt_amount: this.sales_order_items[o].arrears_amount,
            }
            sai.push(acc);
          }//if

        let params = {
          sales_order: this.sales_order_items[o]['id'],
          sale_account_items: sai
        }
        this.loading = true;
        saleOrderReceipt(params).then(data => {
          this.$message.success(this.sales_order_items[o]['number'] + '收款成功');
        }).finally(() => {
          this.loading = false;
        });

        }//for (let o in this.sales_order_items)

      },


      handelAddAcount() {
        this.sales_account_items.push({
          account: '',
          collection_amount: 0,
          receipt_amount: 0
        });
        //console.log(this.sales_account_items)
      },
      removeAccount(item) {
        this.sale_account_items = this.$functions.removeItem(this.sales_account_items, item);
      },
      changeAccount(value, item, idx) {
        let count = this.sales_account_items.filter((_item) => {
          return _item.account == value;
        })
        if (count.length > 1) {
          this.$message.warn('已添加过该结算账户!');
          this.sales_account_items[idx].account = '';
        }
      },


    },
    mounted() {
      this.initData();

    },
  }
</script>
<style>
</style>
